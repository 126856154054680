// src/App.js
import React, { useEffect } from 'react';
import { useDarkMode } from '../../contexts/DarkModeContext';
import GameCard from '../../components/GameCard/GameCard'
import { Helmet } from 'react-helmet';
import AdCMP from '../../components/ADS/AdCMP';

const Home = () => {
    const { gamesList } = useDarkMode();

    return (
        <div>
            {/* <Helmet>
                <meta charset="utf-8" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="TheGameZone.fun" />
                <meta name="HandheldFriendly" content="true" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="google-adsense-account" content="ca-pub-8383910619750608" />
                <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo.png" />
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
                <meta name="viewport"
                    content="width=device-width, initial-scale=1.0, minimal-ui, shrink-to-fit=no, viewport-fit=cover" />

                <title>Play Online Games on Thegamezone: Best Free Games Online | GameZone</title>
                <meta name="title" content="Play Online Games on Thegamezone: Best Free Games Online | GameZone" />
                <meta name="description"
                    content="Play free online games at TheGameZone, the best place to play high-quality browser games. Play a wide genre of online games instantly with no download or sign-up for free!" />

                <meta name="keywords" content="free online games, play games online, TheGameZone, best free games, online gaming" />

                <meta property="og:title" content="Play Online Games on Thegamezone: Best Free Games Online | GameZone" />
                <meta property="og:description"
                    content="Play free online games at TheGameZone, the best place to play high-quality browser games. Play a wide genre of online games instantly with no download or sign-up for free!" />
                <meta property="og:url" content="https://thegamezone.fun/" />
                <meta property="og:site_name" content="TheGameZone.fun" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://thegamezone.fun/logo.png" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Play Online Games on Thegamezone: Best Free Games Online | GameZone" />
                <meta name="twitter:description"
                    content="Play free online games at TheGameZone, the best place to play high-quality browser games. Play a wide genre of online games instantly with no download or sign-up for free!" />
                <meta name="twitter:image" content="https://thegamezone.fun/logo.png" />

                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://thegamezone.fun/" />
            </Helmet> */}

            <div className="p-4 mt-10 sm:ml-56">
                <div className="rounded-lg w-full h-fit text-center items-center">
                    <div className='hidden xl:block'>
                        <AdCMP adSize='1200x280' dataAdSlot="2574950302" />
                    </div>

                    <div className='hidden lg:block xl:hidden'>
                        <AdCMP adSize='1000x280' dataAdSlot="2574950302" />
                    </div>

                    <div className='hidden md:block lg:hidden'>
                        <AdCMP adSize='744x280' dataAdSlot="2574950302" />
                    </div>

                    <div className='block md:hidden'>
                        <AdCMP adSize={`300x250`} dataAdSlot="2574950302" />
                    </div>
                </div>
                <h1 className='text-black dark:text-white font-bold text-[24px]'>👍 You may like</h1>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-10 gap-4 mt-2">
                    {gamesList && gamesList?.slice(0, 50)?.map((_ele, i) => {
                        const GetSlug = _ele?.name?.en?.toLowerCase().replace(/\s+/g, "-");
                        return (<>
                            <GameCard key={i} id={i} slug={GetSlug} name={_ele?.name?.en} banner={_ele?.assets?.square} link={_ele?.URL} />
                        </>
                        )
                    }

                    )}
                    {gamesList.length === 0 && <div className="col-span-12 lg:col-span-12 rounded-lg bg-white w-full h-40 flex justify-center items-center my-4">
                        <h2 className='text-[28px] font-bold'>
                            No games found.
                        </h2>
                    </div>
                    }
                </div>
                <div className="rounded-lg w-full h-fit text-center items-center">
                    <div className='hidden xl:block'>
                        <AdCMP adSize='1200x280' dataAdSlot="2574950302" />
                    </div>

                    <div className='hidden lg:block xl:hidden'>
                        <AdCMP adSize='1000x280' dataAdSlot="2574950302" />
                    </div>

                    <div className='hidden md:block lg:hidden'>
                        <AdCMP adSize='744x280' dataAdSlot="2574950302" />
                    </div>

                    <div className='block md:hidden'>
                        <AdCMP adSize={`300x250`} dataAdSlot="2574950302" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
